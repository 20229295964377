<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
              this.$t('report.company_dashboard.reports') }}
            </span>
          </li>
          <li>{{ this.$t('general.organization_reports') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ this.$t('general.organization_reports') }}</span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->

    <!--End Modal Profile -->
    <!-- Table -->
    <default-loading v-if="data_loading"></default-loading>
    <template v-else>
      <div class="uk-grid-small uk-child-width-expand@s uk-text-center mb-4" uk-grid>
        <div v-if="data_items.populer_post && data_items.populer_post.user">
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-coffee"></i>
              <h5>En Popüler Gönderi '{{ data_items.populer_post.user.name }} {{ data_items.populer_post.user.surname }}'
                tarafından paylaşıldı</h5>
              <h4>"{{ data_items.populer_post.text }}"</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid-small uk-child-width-expand@s uk-text-center mb-4" uk-grid>
        <div>
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-users-alt"></i>
              <h3>{{ data_items.user_count }}</h3>
              <h4>{{ this.$t('general.total_number_of_users') }}</h4>
            </div>
          </div>
        </div>
        <div>
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-home-alt"></i>
              <h3>{{ data_items.login_count }}</h3>
              <h4>{{ this.$t('general.total_number_of_login') }}</h4>
            </div>
          </div>
        </div>
        <div>
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-gift"></i>
              <h3>{{ data_items.gift_count }}</h3>
              <h4>{{ this.$t('general.number_of_gifts_earned') }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid-small uk-child-width-expand@s uk-text-center mb-4" uk-grid>
        <div>
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-books"></i>
              <h3>{{ data_items.training_count }}</h3>
              <h4>{{ this.$t('general.number_of_catalog_trainings') }}</h4>
            </div>
          </div>
        </div>
        <div>
          <div class="course-card uk-card-hover foruser">
            <div class="course-card-body">
              <i class="uil-location-arrow"></i>
              <h3>{{ data_items.assignment_count }}</h3>
              <h4>{{ this.$t('general.number_of_trainings_assigned') }}</h4>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportInstitutions",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: { Pagination, UserProfile, DefaultLoading },
  data() {
    return {
      data_items: [],
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    data_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    filterResult(filter = null) {
      let self = this;
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/institution",
        })
        .then((response) => {
          if (response.status) {
            self.data_items = response.data;
          }
        });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.filterResult();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
